/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import './Homepage.css'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import moneyPicFour from '../../assets/images/money-four.gif'
import userPic from '../../assets/images/user-pic.gif'
import CoinStack from '../../assets/images/coin-stack.gif'
import FirebaseIcon from '../../assets/images/firebase.gif'
import FriendTwo from '../../assets/images/partner2.gif'
import StreakPic from '../../assets/images/streak.gif'
import MongoPic from '../../assets/images/mongo.gif'
import BlockListPic from '../../assets/images/blockList.gif'
import StripePic from '../../assets/images/stripe.gif'
import CommunityPic from '../../assets/images/community.gif'
import CustomWindow from '../../assets/images/customWindow.gif'
import GmailIcon from '../../assets/images/gmail.gif'
import ticketPic from '../../assets/images/tickets.gif'
import feedbackPic from '../../assets/images/feedback.png'

import ReactGA from 'react-ga'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { showTimeInAgoFormat } from '../../Utils/time'
import secureLocalStorage from 'react-secure-storage'

const Homepage = () => {
    let navigate = useNavigate()

    const handleUserInfo = () => {
        ReactGA.event({
            category: 'User Information',
            action: 'Clicked on User Information card',
            label: 'Label for User Information',
            value: 'Value of User Information page'
        })
        navigate(`/userinfo`)
    }

    const loadFromLocalStorage = () => {
        try {
            const savedStatsData = secureLocalStorage.getItem('stats_data')
            if (!savedStatsData) {
                return { viewsTotal: 40131, activityCount: 30123 }
            }
            return savedStatsData
        } catch (err) {
            return { viewsTotal: 40131, activityCount: 30123 }
        }
    }

    const [userData, setUserData] = useState([{ fullname: 'Kiranpal Singh', lastActive: 1706336166686 }])
    const [statsData, setStatsData] = useState(loadFromLocalStorage())
    const [apiCalled, setApiCalled] = useState(false)

    const [activeTab, setActiveTab] = useState('stats')

    const handleTabClick = (tabId) => {
        setActiveTab(tabId)
    }

    const handleCoins = () => {
        navigate(`/coins`)
    }

    const handleUnsubscribeUserFromMails = () => {
        navigate('/unsubscribe-mails')
    }

    const handlePartner = () => {
        navigate(`/partner`)
    }

    const handleCommunity = () => {
        navigate('/community')
    }

    const handlePremiumPurchase = () => {
        navigate(`/premiumplan`)
    }

    const handleFirebase = () => {
        navigate('/firebase')
    }

    const streakHandler = () => {
        navigate('/streak')
    }

    const mongoHandler = () => {
        navigate('/mongo')
    }

    const handleStripe = () => {
        navigate('/stripe')
    }

    const handleBlockList = () => {
        navigate('/blocklist')
    }

    const handleGmailUsers = () => {
        navigate('/gmail-users')
    }

    const handleBlockWindow = () => {
        navigate('/blockWindow')
    }

    const handleRouteHandler = (route) => {
        navigate('/' + route)
    }

    const showRecentUsers = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }

        // Peers
        fetch('https://dev.blockerx.net/api/dashboard/peers', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                if (data.status === 200) {
                    secureLocalStorage.setItem('dashboard_peers', data.data.users)
                    setUserData(data.data.users.slice(0, 5))
                } else {
                }
            })
            .catch()
        // Dashboard stats
        fetch('https://dev.blockerx.net/api/dashboard/stats', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                if (data.status === 'success') {
                    setStatsData(data.data)
                    secureLocalStorage.setItem('stats_data', data.data)
                    setApiCalled(true)
                } else {
                }
            })
            .catch()
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
        if (!apiCalled) {
            showRecentUsers()
        }
    }, [])

    return (
        <Box sx={{ flexGrow: 1 }} className='px-5 pb-10 homepage'>
            <Grid container spacing={2}>
                <Grid item lg={1.5} md={3} sm={4} align='center' marginTop={5} onClick={handleUserInfo}>
                    <Card sx={{ maxWidth: 360 }} className='gridBox'>
                        <CardMedia component='img' height='50' image={userPic} alt='User Info' />
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                User Info
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item lg={1.5} md={3} sm={4} align='center' marginTop={5} onClick={handlePremiumPurchase}>
                    <Card sx={{ maxWidth: 360 }} className='gridBox'>
                        <CardMedia component='img' height='50' image={moneyPicFour} alt='Track Sleep' />
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                Premium
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item lg={1.5} md={3} sm={4} align='center' marginTop={5} onClick={() => handleRouteHandler('customer-tickets')}>
                    <Card sx={{ maxWidth: 360 }} className='gridBox'>
                        <CardMedia component='img' height='50' sx={{ padding: '10px' }} image={ticketPic} alt='NPS' />
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                Tickets
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item lg={1.5} md={3} sm={4} align='center' marginTop={5} onClick={streakHandler}>
                    <Card sx={{ maxWidth: 360 }} className='gridBox'>
                        <CardMedia component='img' height='50' image={StreakPic} alt='Streak' />
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                Streak
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item lg={1.5} md={3} sm={4} align='center' marginTop={5} onClick={() => handleRouteHandler('tickets-feedback')}>
                    <Card sx={{ maxWidth: 360 }} className='gridBox'>
                        <CardMedia component='img' height='50' sx={{ padding: '40px' }} image={feedbackPic} alt='NPS' />
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                Feedback
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item lg={1.5} md={3} sm={4} align='center' marginTop={5} onClick={handlePartner}>
                    <Card sx={{ maxWidth: 360 }} className='gridBox'>
                        <CardMedia component='img' height='50' image={FriendTwo} alt='Track Sleep' />
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                Partner
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item lg={1.5} md={3} sm={4} align='center' marginTop={5} onClick={handleFirebase}>
                    <Card sx={{ maxWidth: 360 }} className='gridBox'>
                        <CardMedia component='img' height='50' image={FirebaseIcon} alt='Firebase' />
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                Firebase
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item lg={1.5} md={3} sm={4} align='center' marginTop={5} onClick={mongoHandler}>
                    <Card sx={{ maxWidth: 360 }} className='gridBox'>
                        <CardMedia component='img' height='50' image={MongoPic} alt='Coins' />
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                MongoDB
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item lg={1.5} md={3} sm={4} align='center' marginTop={5} onClick={handleCoins}>
                    <Card sx={{ maxWidth: 360 }} className='gridBox'>
                        <CardMedia component='img' height='50' image={CoinStack} alt='Coins' />
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                Coins
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item lg={1.5} md={3} sm={4} align='center' marginTop={5} onClick={handleUnsubscribeUserFromMails}>
                    <Card sx={{ maxWidth: 360 }} className='gridBox'>
                        <CardMedia component='img' height='50' image={GmailIcon} alt='Coins' />
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                Unsubscribe
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item lg={1.5} md={3} sm={4} align='center' marginTop={5} onClick={handleBlockList}>
                    <Card sx={{ maxWidth: 360 }} className='gridBox'>
                        <CardMedia component='img' height='50' image={BlockListPic} alt='Block List' />
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                Blocklist
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item lg={1.5} md={3} sm={4} align='center' marginTop={5} onClick={handleBlockWindow}>
                    <Card sx={{ maxWidth: 360 }} className='gridBox'>
                        <CardMedia component='img' height='50' image={CustomWindow} alt='Coins' />
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                Window
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item lg={1.5} md={3} sm={4} align='center' marginTop={5} onClick={handleStripe}>
                    <Card sx={{ maxWidth: 360 }} className='gridBox'>
                        <CardMedia component='img' height='50' image={StripePic} alt='Coins' />
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                Stripe
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item lg={1.5} md={3} sm={4} align='center' marginTop={5} onClick={handleCommunity}>
                    <Card sx={{ maxWidth: 360 }} className='gridBox'>
                        <CardMedia component='img' height='50' image={CommunityPic} alt='Coins' />
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                Community
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item lg={1.5} md={3} sm={4} align='center' marginTop={5} onClick={handleGmailUsers}>
                    <Card sx={{ maxWidth: 360 }} className='gridBox'>
                        <CardMedia component='img' height='50' image={GmailIcon} alt='Coins' />
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                Gmail
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item lg={1.5} md={3} sm={4} align='center' marginTop={5} onClick={() => handleRouteHandler('reply-to-reviews')}>
                    <Card sx={{ maxWidth: 360 }} className='gridBox'>
                        <CardMedia component='img' height='50' image={'https://common-icons.s3.ap-south-1.amazonaws.com/apk.gif'} alt='NPS' />
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                Reviews
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <div className='grid grid-cols-2 md:grid-cols-3 gap-4 mt-10'>
                <div>
                    <Card className='cursor-pointer' onClick={() => handleRouteHandler('push-premium')}>
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                Push Premium Data
                            </Typography>
                            <Typography variant='body2'>Google and Stripe</Typography>
                        </CardContent>
                    </Card>
                </div>
                <div>
                    <Card className='cursor-pointer' onClick={() => handleRouteHandler('community-user')}>
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                Community Status
                            </Typography>
                            <Typography variant='body2'>Check status of community features</Typography>
                        </CardContent>
                    </Card>
                </div>
                <div>
                    <Card className='cursor-pointer' onClick={() => handleRouteHandler('mail-logs')}>
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                Mail Logs
                            </Typography>
                            <Typography variant='body2'>Get User mail logs</Typography>
                        </CardContent>
                    </Card>
                </div>
                <div>
                    <Card className='cursor-pointer' onClick={() => handleRouteHandler('custom-mail')}>
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                Send Custom Mail
                            </Typography>
                            <Typography variant='body2'>Send custom mail to user</Typography>
                        </CardContent>
                    </Card>
                </div>
                <div>
                    <Card className='cursor-pointer' onClick={() => handleRouteHandler('migrate')}>
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                Migrate User Data
                            </Typography>
                            <Typography variant='body2'>Get User mail logs</Typography>
                        </CardContent>
                    </Card>
                </div>
                <div>
                    <Card className='cursor-pointer' onClick={() => handleRouteHandler('redeem-coin-logs')}>
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                Redeem Coin Logs
                            </Typography>
                            <Typography variant='body2'>Get User mail logs</Typography>
                        </CardContent>
                    </Card>
                </div>
                <div>
                    <Card className='cursor-pointer' onClick={() => handleRouteHandler('stripeRevenue')}>
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                Stripe Revenue
                            </Typography>
                            <Typography variant='body2'>Check Revenue from Stripe UAE account</Typography>
                        </CardContent>
                    </Card>
                </div>
                <div>
                    <Card className='cursor-pointer' onClick={() => handleRouteHandler('ticket-logs')}>
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                Ticket Logs
                            </Typography>
                            <Typography variant='body2'>Ticket count per day</Typography>
                        </CardContent>
                    </Card>
                </div>
                <div>
                    <Card className='cursor-pointer' onClick={() => handleRouteHandler('zenze-logs')}>
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                Zenze API Logs
                            </Typography>
                            <Typography variant='body2'>Check Zenze API Logs</Typography>
                        </CardContent>
                    </Card>
                </div>
                <div>
                    <Card className='cursor-pointer' onClick={() => handleRouteHandler('blockerx-pro-whitelist')}>
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                BlockerX Pro Whitelist
                            </Typography>
                            <Typography variant='body2'>Check user whitelist</Typography>
                        </CardContent>
                    </Card>
                </div>
                <div>
                    <Card className='cursor-pointer' onClick={() => handleRouteHandler('chatbot-logs')}>
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                Chatbot Logs
                            </Typography>
                            <Typography variant='body2'>Customer Chatbot Conversations</Typography>
                        </CardContent>
                    </Card>
                </div>
                <div>
                    <Card className='cursor-pointer' onClick={() => handleRouteHandler('zenze-userinfo')}>
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                Zenze User info
                            </Typography>
                            <Typography variant='body2'>Zenze User Info</Typography>
                        </CardContent>
                    </Card>
                </div>
                <div>
                    <Card className='cursor-pointer' onClick={() => handleRouteHandler('push-zenze-premium')}>
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                Push Zenze Premium
                            </Typography>
                            <Typography variant='body2'>Add Zenze Premium</Typography>
                        </CardContent>
                    </Card>
                </div>
                <div>
                    <Card className='cursor-pointer' onClick={() => handleRouteHandler('restrict-apk')}>
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                Restrict BlockerX APK Updates
                            </Typography>
                            <Typography variant='body2'>Check user status</Typography>
                        </CardContent>
                    </Card>
                </div>
                <div>
                    <Card className='cursor-pointer' onClick={() => handleRouteHandler('blocked-items')}>
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                Blocked List
                            </Typography>
                            <Typography variant='body2'>Checked when the user was blocked</Typography>
                        </CardContent>
                    </Card>
                </div>
                <div>
                    <Card className='cursor-pointer' onClick={() => handleRouteHandler('user-restriction')}>
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                User Restriction
                            </Typography>
                            <Typography variant='body2'>Check user restrictions</Typography>
                        </CardContent>
                    </Card>
                </div>
                <div>
                    <Card className='cursor-pointer' onClick={() => handleRouteHandler('zia-users')}>
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                Zia Users
                            </Typography>
                            <Typography variant='body2'>Check zia users</Typography>
                        </CardContent>
                    </Card>
                </div>
                <div>
                    <Card className='cursor-pointer' onClick={() => handleRouteHandler('zenze-analysis')}>
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                Zenze Analysis
                            </Typography>
                            <Typography variant='body2'>Zenze Analysis List</Typography>
                        </CardContent>
                    </Card>
                </div>
                <div>
                    <Card className='cursor-pointer' onClick={() => handleRouteHandler('zenze-instagram-challenge')}>
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                Instagram Challenge
                            </Typography>
                            <Typography variant='body2'>Zenze Instagram Challenge</Typography>
                        </CardContent>
                    </Card>
                </div>
                <div>
                    <Card className='cursor-pointer' onClick={() => handleRouteHandler('zenze-app-challenges')}>
                        <CardContent>
                            <Typography gutterBottom variant='h5' component='div'>
                                Zenze App Challenges
                            </Typography>
                            <Typography variant='body2'>Zenze App Challenges</Typography>
                        </CardContent>
                    </Card>
                </div>
            </div>
            <div className='grid grid-cols-2 gap-4 mt-10 flex'>
                <div className=''>
                    <Card className='flex flex-col h-full'>
                        <CardContent>
                            <Typography className='text-center' gutterBottom variant='h5' component='div'>
                                Recent Active Users
                            </Typography>
                            {userData.map((user, index) => (
                                <div key={index}>
                                    <div class='flex items-center justify-between'>
                                        <Typography class='text-sm'>{user.fullname}</Typography>
                                        <Typography class='text-sm'>{showTimeInAgoFormat(user.lastActive)}</Typography>
                                    </div>
                                    {index !== userData.length - 1 && <hr class='flex-grow border-t-1 border-gray-300 my-4' />}
                                </div>
                            ))}
                        </CardContent>
                    </Card>
                </div>
                <div className=''>
                    <Card className='flex flex-col h-full'>
                        <CardContent>
                            <Typography className='text-center' gutterBottom>
                                <span className='text-3xl font-medium'>Dashboard Stats</span>
                            </Typography>
                            <div class='flex items-center justify-between'>
                                <Typography class=''>Views</Typography>
                                <Typography class=''>{statsData.viewsTotal || 38123}</Typography>
                            </div>
                            <hr class='flex-grow border-t-1 border-gray-300 my-4' />
                            <div class='flex items-center justify-between'>
                                <Typography class=''>Activity</Typography>
                                <Typography class=''>{statsData.activityCount || 27121}</Typography>
                            </div>
                            <hr class='flex-grow border-t-1 border-gray-300 my-4' />
                            <div class='flex items-center justify-between'>
                                <Typography class=''>Hours Saved</Typography>
                                <Typography class=''>{(statsData.activityCount / 99).toFixed(2) || (27121 / 99).toFixed(2)}</Typography>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </Box>
    )
}

export default Homepage
